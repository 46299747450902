import axios from 'axios';

import { IOrder } from '@/shared/model/order.model';
import { IBillingParamsSingle } from '@/shared/model/billing-params-single.model';
import { IBillingParamsMultiple } from '@/shared/model/billing-params-multiple.model';
import { IUpdateScheduledParam } from '@/shared/model/updateScheduledParam.model';

const baseApiUrl = 'api/orders';

export default class OrderService {
  public find(id: number): Promise<IOrder> {
    return new Promise<IOrder>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public getNextBillingNumber(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/billing-number/next-id`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public getMaxOrderNumber(): Promise<number> {
    return new Promise<number>((resolve, reject) => {
      axios
        .get('api/max-order-number')
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public retrieve(customerId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/customer/${customerId}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public delete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public deleteFinally(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/delete/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public convertToOrderList(orderId: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/to-list/${orderId}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public markAsDelete(id: number): Promise<any> {
    return new Promise<any>((resolve, reject) => {
      axios
        .delete(`${baseApiUrl}/mark/${id}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public create(entity: IOrder): Promise<IOrder> {
    return new Promise<IOrder>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public update(entity: IOrder): Promise<IOrder> {
    return new Promise<IOrder>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/${entity.id}`, entity)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public updateDelivery(orderIds: number[], deliveryDate) {
    return new Promise<IOrder[]>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/update-delivery`, { orderIds, deliveryDate })
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public updateScheduled(updateParam: IUpdateScheduledParam) {
    return new Promise<IOrder[]>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/update-scheduled`, updateParam)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public checkForNewOrders() {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/new-orders`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public generateBillingPdfSingle(billingParamsSingle: IBillingParamsSingle) {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/billing-pdf/single`, billingParamsSingle)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public generateBillingPdfMultiple(billingParamsMultiple: IBillingParamsMultiple) {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/billing-pdf/multiple`, billingParamsMultiple)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public sendBillingPdfMails(orderIds: number[]) {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/mail-billing-pdf`, orderIds)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public downloadSingleBillingPdf(id: number) {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(
          `${baseApiUrl}/download-billing-pdf`,
          {
            orderId: id,
          },
          { responseType: 'blob' }
        )
        .then(res => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.setAttribute('download', res.headers['content-disposition'].split('filename=')[1]);
          link.href = url;
          link.click();
          window.URL.revokeObjectURL(url);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public downloadAllBillingPdf(orderIds: number[]) {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/download-all-billing-pdf`, orderIds, { responseType: 'blob' })
        .then(res => {
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement('a');
          link.setAttribute('download', res.headers['content-disposition'].split('filename=')[1]);
          link.href = url;
          link.click();
          window.URL.revokeObjectURL(url);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public deleteAll(orderIds: number[]) {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/delete-all`, orderIds)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public deleteFinallyAll(orderIds: number[]) {
    return new Promise<any>((resolve, reject) => {
      axios
        .put(`${baseApiUrl}/delete-finally-all`, orderIds)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public redButton(active: boolean) {
    return new Promise<any>((resolve, reject) => {
      axios
        .post(`${baseApiUrl}/red-button?active=${active}`)
        .then(res => {
          resolve(res);
        })
        .catch(err => {
          reject(err);
        });
    });
  }

  public similarBlacklisted(orderId: number) {
    return new Promise<any>((resolve, reject) => {
      axios
        .get(`${baseApiUrl}/similar-blacklisted/${orderId}`)
        .then(res => {
          resolve(res.data);
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
